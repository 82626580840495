<template>
  <!-- 明細 -->
  <div>
    <div class="addPerson__wrap" v-show="visible">
      <el-header class="shadow page-header__wrap">
        <div class="flex">
          <div class="backBtn" @click="handleReturn">
            <el-image class="back-img" :src="require('@/assets/img/back.png')"></el-image>
            <span>返回</span>
          </div>
          <div class="title">
            <span class="site">当前位置：</span>
            {{ $route.meta.title }}
            <span> > </span>
            查看明细
          </div>
        </div>
      </el-header>
      
      <div class="main">
        <div class="mainBox" style="display: flex;flex-direction: column;">
          <div>
            <div style="margin-bottom:10px;font-weight:bold;">采购日期：{{this.currRow.delivery_date}}</div>
            <div style="display:flex;align-items: center;margin-bottom:10px;font-size:14px;">
              <el-form ref="elFormDom" inline :model="table.params" :size="subUnitSize">
                <el-form-item label="供应商" prop="supplier_id">
                  <el-select v-model="table.params.supplier_id" filterable id="supplier_id" clearable @change="onSearch">
                    <el-option v-for="item in supplierArr" :label="item.name" :value="item.id" :key="item.id">
                      <span style="float: left">{{ item.name }}</span>
                      <span style="float: right; color: #8492a6; font-size: 13px">{{ item.type == 1 ? '供应商' : '采购员' }}</span>
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="商品名称" prop="goods_name">
                  <el-input
                    size="mini"
                    v-model.trim="table.params.goods_name"
                    placeholder="请输入商品名称"
                    clearable
                    style="width:220px;"
                    @clear="onSearch"
                    @keydown.enter.native="onSearch"
                  ></el-input>
                </el-form-item>
                <el-form-item label="食堂名称" prop="address_id">
                  <el-select v-model="table.params.address_id" filterable placeholder="请输入" clearable @focus="getStoreSel" @change="onSearch1">
                    <el-option v-for="item in storeArr" :key="item.id" :label="item.name" :value="item.id">
                      <span style="float: left">{{ item.name }}</span>
                      <span style="float: right; color: #8492a6; font-size: 13px">({{ item.merchant_name }})</span>
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="" prop="is_self">
                  <el-checkbox v-model="table.params.is_self" @change="onSearch2">平台自采</el-checkbox>
                </el-form-item>
                <el-form-item>
                  <el-button type="primary" icon="el-icon-search" @click="onSearch">查询</el-button>
                  <el-button icon="el-icon-refresh-right" @click="resetSel">重置</el-button>
                </el-form-item>
              </el-form>
            </div>
          </div>
          <div ref="tableDom" style="flex:1;">
            <el-table
              :data="table.data"
              style="width: 100%"
              :row-key="row => row.id"
              border
              size="mini"
              align="center"
              :height="height"
              :header-cell-style="{background:'#f5f5f5'}">
              <el-table-column
                type="index"
                label="序号"
                width="60"
                align="center">
              </el-table-column>
              <el-table-column align="center" property="supplier_name" label="供应商名称" width="180" :show-overflow-tooltip="true"></el-table-column>
              <el-table-column align="center" property="goods_name" label="商品名称"  width="160" :show-overflow-tooltip="true"></el-table-column>
              <el-table-column align="center" property="unit_name" label="单位"></el-table-column>
              <el-table-column align="center" property="spec_name" label="规格"></el-table-column>
              <el-table-column align="center" property="num" label="采购数量"></el-table-column>
              <el-table-column align="center" property="delivery_num" label="收货数量"></el-table-column>
              <el-table-column align="center" property="purchase_price" label="收货单价(元)" width="100"></el-table-column>
              <el-table-column align="center" property="total_purchase_price" label="采购金额(元)" width="100"></el-table-column>
              <el-table-column align="center" property="delivery_time" label="收货时间" width="140"></el-table-column>
              <el-table-column align="center" property="address_name" label="食堂名称" width="240">
                <template slot-scope="{row}">
                  <div v-for="(item,index) in row.details" :key="index" class="intWrap">
                    <span v-if="!!item.address_name && !!item.merchant_name">
                      {{item.address_name}}（{{item.merchant_name}}）
                    </span>
                    <span v-else>平台自采</span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column align="center" property="out_num" label="出库数量" width="120">
                <template slot-scope="{row}">
                  <div v-for="(item,index2) in row.details" :key="index2" class="intWrap">
                    {{item.out_num}}
                  </div>
                </template>
              </el-table-column>
              <el-table-column align="center" property="text" label="出库时间" width="140" >
                <template slot-scope="{row}">
                  <div v-for="(item,index3) in row.details" :key="index3" class="intWrap">
                    {{item.sorter_time}}
                  </div>
                </template>
              </el-table-column>
              <el-table-column align="center" property="surplus_num" label="剩余库存">
                <template slot-scope="{row}">
                  <span :class="Number(row.surplus_num) < 0 ? 'redColor' : '' ">{{row.surplus_num}}</span>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="pageWrap" v-if="table.total > 10">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="table.params.page"
              :page-sizes="[10, 20, 50, 100]"
              :page-size="table.params.count"
              layout="total, sizes, prev, pager, next, jumper"
              :total="table.total">
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mixinTable } from '@/mixins/table.js'
  export default {
    name: 'BuillDetail',
    mixins: [mixinTable],
    data() {
      return {
        subUnitSize: this.$store.state.subUnitSize, // 获取组件 size属性
        visible: false,
        isChange: false,
        height: 0,
        table: {
          params:{
            page:1,
            count:10,
            year: '',
            month: '',
            day: '',
            supplier_id: '',
            address_id: '',
            goods_name: '',
            is_self: false, // 平台自采 1:是(true) 2:否(false)
          },
          total: 0,
          data:[],
        },
        currRow: {},
        supplierArr: [],
        storeArr: [],
      }
    },
    
    methods: {
      getDetail(row) {
        this.getSupplierSel();
        this.currRow = row;
        this.table.params.supplier_id = row.supplier_id; // 供应商id
        this.getTable()
        this.$nextTick(() => {
          this.height = this.$refs.tableDom.offsetHeight - 40 || 500
        })
      },
      reset(done) {
        this.isChange = false;
        done && done()
      },
      toggle(show) {
        this.visible = show
        !show && this.reset()
      },
      resetSel() {
        this.$refs.elFormDom.resetFields()
        this.table.params.goods_name = '';
        this.table.params.day = '';
        this.table.params.is_self = false;
        this.table.params.page = 1;
        this.table.params.count = 10;
        this.getTable();
      },
      onSearch1() {
        this.table.params.page = 1;
        this.table.params.is_self = false;
        this.table.data = [];
        this.getTable();
      },
      onSearch2() {
        this.table.params.page = 1;
        this.table.params.address_id = '';
        this.table.data = [];
        this.getTable();
      },
      // 详情
      getTable() {
        let _params = {
          year: this.currRow.year, // 下单年份
          month: this.currRow.month, // 下单月份
          day: this.currRow.delivery_date, // 日期
          supplier_id: this.table.params.supplier_id, // 供应商id
          goods_name: this.table.params.goods_name, // 商品名称
          address_id: this.table.params.address_id, // 食堂名称
          is_self: !!this.table.params.is_self ? 1 : '', // 平台自采 1:是 2:否
          page: this.table.params.page,
          count: this.table.params.count,
        }
        this.$http.get("/admin/purchase_stat/info",{params: _params}).then((res) => {
          if (res.code === 1) {
            this.table.loading = false;
            this.table.data = res.data.list
            this.table.total = res.data.total;
          }
        })
      },
      // 返回
      handleReturn() {
        this.$emit("refresh")
        this.toggle(false)
        this.table.data = []
      },
    }
  }
</script>

<style scoped lang="scss">
.addPerson__wrap {
  @include position($t: 0, $r: 0, $b: 0, $l: 0);
} 
.addPerson__wrap .main {
  overflow-x: hidden;
  background: #fff;
}
::v-deep .el-table .cell {
  padding: 0 !important;
}
.pageWrap{
  margin-top: 10px;
  text-align: right;
  margin-right: 50px;
}
.intWrap {
  border-top: solid 1px #eee;
  height: 40px;
  line-height: 40px;
}
.intWrap:first-child {
  border: none !important;
}
</style>
